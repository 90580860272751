// import React, { useState } from "react";

// export default function Filter() {
//   const [selectedState, setSelectedState] = useState("");
//   const [selectedCity, setSelectedCity] = useState("");

//   // Data for dropdown (static array of states)
//   const states = [
//     "Andhra Pradesh",
//     "Arunachal Pradesh",
//     "Assam",
//     "Bihar",
//     "Chhattisgarh",
//     "Goa",
//     "Gujarat",
//     "Haryana",
//     "Himachal Pradesh",
//     "Jharkhand",
//     "Karnataka",
//     "Kerala",
//     "Madhya Pradesh",
//     "Maharashtra",
//     "Manipur",
//     "Meghalaya",
//     "Mizoram",
//     "Nagaland",
//     "Odisha",
//     "Punjab",
//     "Rajasthan",
//     "Sikkim",
//     "Tamil Nadu",
//     "Telangana",
//     "Tripura",
//     "Uttar Pradesh",
//     "Uttarakhand",
//     "West Bengal",
//     "Andaman and Nicobar Islands",
//     "Chandigarh",
//     "Daman and Diu",
//     "Jammu and Kashmir",
//     "Ladakh",
//     "Lakshadweep",
//     "Delhi",
//     "Puducherry",
//   ];

//   const handleChangeState = (event) => {
//     setSelectedState(event.target.value);
//   };
//   const handleChangeCity = (event) => {
//     setSelectedCity(event.target.value);
//   };
//   return (
//     <>
//       <div className='container mt-4 bg-primary rounded'>
//         <div className='row'>
//           <div className='col-lg-6 col-md-6 col-sm-12 p-3'>
//             <h3>Select State</h3>
//             <select
//               className='form-select'
//               value={selectedState}
//               onChange={handleChangeState}
//             >
//               <option value=''>--Select a state--</option>
//               {states.map((state, index) => (
//                 <option key={index} value={state}>
//                   {state}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className='col-lg-6 col-md-6 col-sm-12 p-3'>
//             <h3>Select City</h3>
//             <select
//               className='form-select'
//               value={selectedState}
//               onChange={handleChangeCity}
//             >
//               <option value=''>--Select a City--</option>
//               {states.map((state, index) => (
//                 <option key={index} value={state}>
//                   {state}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
import React, { useState, useEffect } from "react";

export default function Filter() {
  const [states, setStates] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState(""); // Use stateId instead of StateName
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch states on component mount
  useEffect(() => {
    const fetchStates = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch("http://localhost:8000/api/states");
        if (!response.ok) {
          throw new Error("Failed to fetch states");
        }

        const statesData = await response.json();
        setStates(statesData); // Assuming response is an array of state objects
      } catch (err) {
        setError("Failed to fetch states");
      } finally {
        setLoading(false);
      }
    };
    const fetchCity = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch("http://localhost:8000/api/cities");
        if (!response.ok) {
          throw new Error("Failed to fetch city");
        }

        const cityData = await response.json();

        setCities(cityData); // Assuming response is an array of state objects
      } catch (err) {
        setError("Failed to fetch city");
      } finally {
        setLoading(false);
      }
    };

    fetchStates();
    fetchCity();
  }, []); // Empty dependency array ensures this runs only once, when the component mounts

  // Handle state selection
  const handleChangeState = async (event) => {
    const selectedStateId = event.target.value;
    setSelectedStateId(selectedStateId);
    setSelectedCity(""); // Clear the selected city when state changes
    setCities([]); // Clear the cities list

    if (selectedStateId) {
      setLoading(true);
      setError(null);

      try {
        // Fetch cities based on selected stateId
        const response = await fetch(
          `http://localhost:8000/api/cities/${selectedStateId}`
        );
        if (!response.ok) {
          throw new Error("Cities not found");
        }
        const citiesData = await response.json();
        console.log(citiesData);

        setCities(citiesData);
      } catch (err) {
        setError("Failed to fetch cities");
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle city selection
  const handleChangeCity = (event) => {
    setSelectedCity(event.target.value);
  };

  return (
    <div className='container mt-4 bg-primary rounded'>
      <div className='row'>
        {/* State Dropdown */}
        <div className='col-lg-6 col-md-6 col-sm-12 p-3'>
          <h3>Select State</h3>
          {loading && !states.length ? (
            <p>Loading states...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <select
              className='form-select'
              value={selectedStateId}
              onChange={handleChangeState}
            >
              <option value=''>--Select a state--</option>
              {states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.StateName} {/* Display state name */}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* City Dropdown */}
        <div className='col-lg-6 col-md-6 col-sm-12 p-3'>
          <h3>Select City</h3>
          <select
            className='form-select'
            value={selectedCity}
            onChange={handleChangeCity}
          >
            <option value=''>--Select a City--</option>
            {loading ? (
              <option>Loading cities...</option>
            ) : error ? (
              <option>{error}</option>
            ) : cities.length > 0 ? (
              cities.map((city, index) => (
                <option key={index} value={city.id}>
                  {city.cityname}
                </option>
              ))
            ) : (
              <option>No cities available</option>
            )}
          </select>
        </div>
      </div>
    </div>
  );
}
