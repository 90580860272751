import React from "react";
import Header from "../component/Header";
import Services from "../component/Services";
import Footer from "../component/Footer";
import Gallery from "../component/Gallery";
export default function ServicesPage() {
  return (
    <>
      <Header />
      <Services />
      <Gallery />
      <Footer />
    </>
  );
}
