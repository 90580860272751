import React from "react";

export default function Info() {
  return (
    <>
      <section id='info'>
        <div className='container' data-aos='fade-up'>
          <div className='row'>
            <div className='col-md-3 text-center mb-4 mb-lg-0'>
              <h3 className='display-1 fw-normal text-primary position-relative'>
                250{" "}
                <span className='position-absolute top-50 end-50 translate-middle z-n1 ps-lg-4 pt-lg-4'>
                  <img
                    src='images/pattern1.png'
                    alt='pattern'
                    className='img-fluid'
                  />
                </span>
              </h3>
              <p className='text-capitalize'>Managed office</p>
            </div>
            <div className='col-md-3 text-center mb-4 mb-lg-0'>
              <h3 className='display-1 fw-normal text-primary position-relative'>
                20{" "}
                <span className='position-absolute top-50 translate-middle z-n1'>
                  <img
                    src='images/pattern1.png'
                    alt='pattern'
                    className='img-fluid'
                  />
                </span>
              </h3>
              <p className='text-capitalize'>
                Ready to move <br />
                custom office
              </p>
            </div>
            <div className='col-md-3 text-center mb-4 mb-lg-0'>
              <h3 className='display-1 fw-normal text-primary position-relative'>
                35{" "}
                <span className='position-absolute top-100 pb-5 translate-middle z-n1'>
                  <img
                    src='images/pattern1.png'
                    alt='pattern'
                    className='img-fluid'
                  />
                </span>
              </h3>
              <p className='text-capitalize'>
                Dedicated desk <br />
                with Private Cabin
              </p>
            </div>
            <div className='col-md-3 text-center mb-4 mb-lg-0'>
              <h3 className='display-1 fw-normal text-primary position-relative'>
                20{" "}
                <span className='position-absolute top-50 end-50 pb-lg-4 pe-lg-2 translate-middle z-n1'>
                  <img
                    src='images/pattern1.png'
                    alt='pattern'
                    className='img-fluid'
                  />
                </span>
              </h3>
              <p className='text-capitalize'>
                Conference Room /<br /> Meeting Room Book
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
