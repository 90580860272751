import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Slider from "../component/Slider";
import Aboutus from "../component/Aboutus";
import Info from "../component/Info";
import Spaces from "../component/Spaces";
import Gallery from "../component/Gallery";
import Services from "../component/Services";
import Filter from "../component/Filter";

export default function Home() {
  return (
    <>
      <Header />
      <Slider />
      <Filter />
      <Aboutus />
      <Info />
      <Spaces />
      <Gallery />
      <Services />
      <Footer />
    </>
  );
}
