import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section id='footer'>
        <div
          className='container-fluid padding-side padding-small pt-0'
          data-aos='fade-up'
        >
          <footer className='row'>
            <div className='col-md-6 col-lg-3 mb-4 mb-lg-0'>
              <img
                src='images/invisispacelogo.png'
                alt='logo-footer'
                className='img-fluid'
              />
              <p className='mt-3'>
                Our mission is to provide businesses with flexible,
                fully-equipped environments tailored to their unique needs,
                helping them thrive in a dynamic world.
              </p>
              <ul className='social-links d-flex flex-wrap list-unstyled mt-4 mb-0'>
                <li>
                  <a href='#'>
                    <svg className='social me-4' width='20' height='20'>
                      <use xlinkHref='#facebook'></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <svg className='social me-4' width='20' height='20'>
                      <use xlinkHref='#twitter'></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <svg className='social me-4' width='20' height='20'>
                      <use xlinkHref='#linkedin'></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <svg className='social me-4' width='20' height='20'>
                      <use xlinkHref='#instagram'></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <svg className='social me-4' width='20' height='20'>
                      <use xlinkHref='#youtube'></use>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className='col-md-6 col-lg-3 offset-lg-1 mb-4 mb-lg-0'></div>
            <div className='col-md-6 col-lg-3 offset-lg-1 mb-4 mt-4 mb-lg-0'>
              <h4 className='display-6 fw-normal'>Our Info</h4>
              <ul className='nav flex-column'>
                <li className='location text-capitalize d-flex align-items-center'>
                  <svg className='color me-1' width='20' height='20'>
                    <use xlinkHref='#location'></use>
                  </svg>
                  Invisispace
                </li>

                <li className='text-capitalize ms-4'>
                  Sanganer,Jaipur(Rajasthan)
                </li>
                <li className='phone text-capitalize d-flex align-items-center mt-2'>
                  <svg className='color me-1' width='20' height='20'>
                    <use xlinkHref='#phone'></use>
                  </svg>
                  +91 9116109415
                </li>
                <li className='email text-capitalize d-flex align-items-center mt-2'>
                  <svg className='color me-1' width='20' height='20'>
                    <use xlinkHref='#email'></use>
                  </svg>
                  dheeraj@invisispace.com
                </li>
              </ul>
            </div>
          </footer>
        </div>
        <hr className='text-black' />
        <div
          className='container-fluid padding-side padding-small'
          data-aos='fade-up'
        >
          <footer className='row'>
            <div className='col-md-6 col-lg-3 mb-4 mb-lg-0'>
              <h4 className='display-6 fw-normal'>Quick links</h4>
              <ul className='nav flex-column'>
                <li className='nav-item px-3'>
                  <NavLink className='nav-link p-0' aria-current='page' to='/'>
                    Home
                  </NavLink>
                </li>
                <li className='nav-item px-3'>
                  <NavLink className='nav-link p-0' to='/about'>
                    About
                  </NavLink>
                </li>
                <li className='nav-item px-3'>
                  <NavLink className='nav-link p-0' to='/services'>
                    Services
                  </NavLink>
                </li>
                <li className='nav-item px-3'>
                  <NavLink className='nav-link p-0' to='/spaces'>
                    Spaces
                  </NavLink>
                </li>
                <li className='nav-item px-3'>
                  <NavLink className='nav-link p-0' to='/contact'>
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className='col-md-6 col-lg-3 offset-lg-1 mb-4 mb-lg-0'>
              <h4 className='display-6 fw-normal'>Services</h4>
              <ul className='nav flex-column'>
                <li>Mail Handling</li>
                <li>GST Registration</li>
                <li>Business Registration</li>
                <li>GST Registration with Seat</li>
                <li>Business Registration with Seat</li>
                <li>Office Space</li>
              </ul>
            </div>
            <div className='col-md-6 col-lg-3 offset-lg-1 mb-4 mb-lg-0'>
              <p className='m-0'>© Copyright 2024 invisispace.com </p>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
}
