import React, { useEffect } from "react";

export default function Services() {
  useEffect(() => {
    const page = window.location.pathname.toLocaleString();
    console.log(page);
  }, []);
  return (
    <>
      <section id='services' className='padding-medium'>
        <div className='container-fluid padding-side' data-aos='fade-up'>
          <h3 className='display-3 text-center fw-normal col-lg-4 offset-lg-4'>
            Our services & facilities
          </h3>
          <div className='row mt-5'>
            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Space-Tour'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Space tour</h4>
                <p>
                  Take a Space Tour with InvisiSpace and explore our range of
                  modern office spaces. From private offices to collaborative
                  work areas, our spaces are designed for productivity and
                  flexibility. See firsthand how our stylish, fully equipped
                  environments can support your team and business goals. Book
                  your tour today!
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Space-Shortlisting'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Space Shortlisting</h4>
                <p>
                  With InvisiSpace, finding the perfect office space is simple.
                  Our Space Shortlisting service helps you filter options based
                  on your budget, location, and specific needs. Quickly narrow
                  down your choices to find the ideal workspace that fits your
                  business requirements and style.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Price-Negotiation'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Price negotiation</h4>
                <p>
                  At InvisiSpace, we offer flexible Price Negotiation to ensure
                  you get the best deal. Our team works closely with you to find
                  a pricing plan that suits your budget, without compromising on
                  quality. Let us help you secure the perfect workspace at a
                  price that works for your business.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Contract-Signing'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Timely Handover</h4>
                <p>
                  At InvisiSpace, we pride ourselves on a Timely Handover of
                  your office space. Our team ensures that your space is ready
                  on schedule, so you can start your operations without delay.
                  Move in with confidence, knowing everything will be set up and
                  prepared for you on time.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Timely-Handover'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>Contract Signing</h4>
                <p>
                  The Contract Signing process with InvisiSpace is
                  straightforward and transparent. Once you’ve selected your
                  ideal space, our team ensures that all terms are clear and
                  that you’re comfortable with the agreement. We guide you
                  through every step to ensure a smooth, hassle-free signing
                  experience.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>

            <div className='col-md-6 col-xl-4'>
              <div className='service mb-4 text-center rounded-4 p-5'>
                <div className='position-relative'>
                  <svg className='color' width='70' height='70'>
                    <use xlinkHref='#Onboarding-Settling'></use>
                  </svg>
                  <img
                    src='images/pattern2.png'
                    alt='img'
                    className='position-absolute top-100 start-50 translate-middle z-n1 pe-5'
                  />
                </div>
                <h4 className='display-6 fw-normal my-3'>
                  Onboarding and Settling
                </h4>
                <p>
                  Our Onboarding and Settling process at InvisiSpace is designed
                  to help you get started quickly. We provide support every step
                  of the way, from setting up your workspace to familiarizing
                  your team with the facilities. Enjoy a smooth transition as
                  you settle into your new office.
                </p>
                {window.location.pathname.toLowerCase() ===
                "/services" ? null : (
                  <a href='/services' className='btn btn-arrow'>
                    <span className='text-decoration-underline'>
                      Read More
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
