import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css"; // Import OwlCarousel styles
import "owl.carousel/dist/assets/owl.theme.default.css"; // Import Owl theme styles
import { NavLink } from "react-router-dom";

const rooms = [
  {
    imgSrc: "images/room1.jpg",
    title: "Grand deluxe rooms",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    price: "$299 /Pernight",
    size: "10 ft",
    capacity: "Max person 2",
    bed: "Normal Beds",
    services: "Wifi, Television, Bathroom,...",
    link: "/spaces",
  },
  {
    imgSrc: "images/room3.jpg",
    title: "Sweet Family room",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    price: "$360 /night",
    size: "10 ft",
    capacity: "Max person 4",
    bed: "Normal Beds",
    services: "Wifi, Television, Bathroom,...",
    link: "/spaces",
  },
  {
    imgSrc: "images/room2.jpg",
    title: "Perfect Double Room",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    price: "$450 /night",
    size: "10 ft",
    capacity: "Max person 2",
    bed: "Normal Beds",
    services: "Wifi, Television, Bathroom,...",
    link: "/spaces",
  },
  {
    imgSrc: "images/room1.jpg",
    title: "Grand deluxe rooms",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    price: "$299 /Pernight",
    size: "10 ft",
    capacity: "Max person 2",
    bed: "Normal Beds",
    services: "Wifi, Television, Bathroom,...",
    link: "/spaces",
  },
  {
    imgSrc: "images/room3.jpg",
    title: "Sweet Family room",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    price: "$360 /night",
    size: "10 ft",
    capacity: "Max person 4",
    bed: "Normal Beds",
    services: "Wifi, Television, Bathroom,...",
    link: "/spaces",
  },
  {
    imgSrc: "images/room2.jpg",
    title: "Perfect Double Room",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    price: "$450 /night",
    size: "10 ft",
    capacity: "Max person 2",
    bed: "Normal Beds",
    services: "Wifi, Television, Bathroom,...",
    link: "/spaces",
  },
  // More rooms can be added here...
];

export default function Spaces() {
  const owlSettings = {
    loop: true,
    margin: 30,
    // nav: true, // Enables next/prev navigation
    responsive: {
      0: {
        items: 1, // 1 item on mobile
      },
      600: {
        items: 2, // 2 items on medium screen
      },
      1000: {
        items: 3, // 3 items on larger screens
      },
    },
  };

  return (
    <>
      <section id='room' className='padding-medium'>
        <div className='container-fluid padding-side' data-aos='fade-up'>
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <div>
              <h3 className='display-3 fw-normal text-center'>
                Explore our rooms
              </h3>
            </div>
            <NavLink to='/spaces' className='btn btn-arrow btn-primary mt-3'>
              <span>
                Explore rooms
                <svg width='18' height='18'>
                  <use xlinkHref='#arrow-right'></use>
                </svg>
              </span>
            </NavLink>
          </div>

          <div className='room-swiper mt-5'>
            <OwlCarousel className='owl-theme' {...owlSettings}>
              {rooms.map((room, index) => (
                <div className='item' key={index}>
                  <div className='room-item position-relative bg-black rounded-4 overflow-hidden'>
                    <img
                      src={room.imgSrc}
                      alt={room.title}
                      className='post-image img-fluid rounded-4'
                    />
                    <div className='product-description position-absolute p-5 text-start'>
                      <h5 className='display-6 fw-normal text-white'>
                        {room.title}
                      </h5>
                      <p className='product-paragraph text-white'>
                        {room.description}
                      </p>
                      <table>
                        <tbody>
                          <tr className='text-white'>
                            <td className='pe-2'>Price:</td>
                            <td className='price'>{room.price}</td>
                          </tr>
                          <tr className='text-white'>
                            <td className='pe-2'>Size:</td>
                            <td>{room.size}</td>
                          </tr>
                          <tr className='text-white'>
                            <td className='pe-2'>Capacity:</td>
                            <td>{room.capacity}</td>
                          </tr>
                          <tr className='text-white'>
                            <td className='pe-2'>Bed:</td>
                            <td>{room.bed}</td>
                          </tr>
                          <tr className='text-white'>
                            <td className='pe-2'>Services:</td>
                            <td>{room.services}</td>
                          </tr>
                        </tbody>
                      </table>
                      <NavLink to='/spaces'>
                        <p className='text-decoration-underline text-white m-0 mt-2'>
                          Browse Now
                        </p>
                      </NavLink>
                    </div>
                  </div>
                  <div className='room-content text-center mt-3'>
                    <h5 className='display-6 fw-normal'>
                      <a href={room.link}>{room.title}</a>
                    </h5>
                    <p>
                      <span className='text-primary fs-4'>{room.price}</span>
                      /night
                    </p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
}
