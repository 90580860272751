import React from "react";

export default function Slider() {
  return (
    <>
      <section id='slider' data-aos='fade-up'>
        <div className='container-fluid padding-side'>
          <div className='d-flex rounded-5' style={divStyle}>
            <div className='row align-items-center m-auto pt-5 px-4 px-lg-0'>
              <div className='text-start col-md-6 col-lg-5 col-xl-6 offset-lg-1 '>
                <h2 className='display-1 fw-normal' style={{ marginTop: 170 }}>
                  Invisispace: Work, reimagined.
                </h2>
              </div>
              <div className='col-md-6 col-lg-5 col-xl-4 mt-5 mt-md-0'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
const divStyle = {
  backgroundImage: "url(images/slider-image.jpg)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "85vh",
  backgroundPosition: "center",
};
