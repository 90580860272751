import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import InquiryForm from "../component/InquiryForm";

export default function ContactPage() {
  return (
    <>
      <Header />
      <br />
      <div className='container'>
        <div className='row mt-4'>
          <div className='col-lg-6 col-sm-12'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7122.580265865434!2d75.80328668766528!3d26.798888983400197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dc98a7ab25c69%3A0xcf696fd4824319f2!2sSector%205%2C%20Pratap%20Nagar%2C%20Jaipur%2C%20Rajasthan%20302022!5e0!3m2!1sen!2sin!4v1731597848800!5m2!1sen!2sin'
              style={{ border: 0, width: "100%" }}
              height={450}
              allowfullscreen=''
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
              className='col-lg-6 col-sm-12'
            ></iframe>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <InquiryForm />
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
}
