import React, { useState } from "react";

export default function InquiryForm() {
  // Define state variables for form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    enquirePlan: "",
  });

  // Define state for form validation errors
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contact: "",
    enquirePlan: "",
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Name validation
    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }

    // Contact number validation
    if (!formData.contact) {
      newErrors.contact = "Contact number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.contact)) {
      newErrors.contact = "Contact number must be 10 digits";
      isValid = false;
    }

    // Enquire Plan validation
    if (!formData.enquirePlan) {
      newErrors.enquirePlan = "Please select an enquiry plan";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // If form is valid, process the data
    if (validateForm()) {
      alert("Form submitted successfully!");
      console.log(formData);
      // Clear form after successful submission
      setFormData({
        name: "",
        email: "",
        contact: "",
        enquirePlan: "",
      });
      setErrors({});
    }
  };

  return (
    <div className='container mt-4'>
      <h2 className='text-center mb-4'>Inquiry Form</h2>
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className='mb-3'>
          <label htmlFor='name' className='form-label'>
            Name
          </label>
          <input
            type='text'
            id='name'
            name='name'
            className='form-control'
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          {errors.name && <div className='text-danger'>{errors.name}</div>}
        </div>

        {/* Email */}
        <div className='mb-3'>
          <label htmlFor='email' className='form-label'>
            Email ID
          </label>
          <input
            type='email'
            id='email'
            name='email'
            className='form-control'
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          {errors.email && <div className='text-danger'>{errors.email}</div>}
        </div>

        {/* Contact Number */}
        <div className='mb-3'>
          <label htmlFor='contact' className='form-label'>
            Contact Number
          </label>
          <input
            type='text'
            id='contact'
            name='contact'
            className='form-control'
            value={formData.contact}
            onChange={handleInputChange}
            required
          />
          {errors.contact && (
            <div className='text-danger'>{errors.contact}</div>
          )}
        </div>

        {/* Enquire Plan */}
        <div className='mb-3'>
          <label htmlFor='enquirePlan' className='form-label'>
            Enquire Plan
          </label>
          <select
            id='enquirePlan'
            name='enquirePlan'
            className='form-select'
            value={formData.enquirePlan}
            onChange={handleInputChange}
            required
          >
            <option value=''>--Select Plan--</option>

            <option value='Office Space'>Office Space</option>
          </select>
          {errors.enquirePlan && (
            <div className='text-danger'>{errors.enquirePlan}</div>
          )}
        </div>

        {/* Submit Button */}
        <div className='text-center'>
          <button type='submit' className='btn btn-primary'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
