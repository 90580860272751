import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Aboutus from "../component/Aboutus";
export default function AboutPage() {
  return (
    <>
      <Header />
      <Aboutus />
      <Footer />
    </>
  );
}
