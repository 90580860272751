import React from "react";
import { NavLink } from "react-router-dom";

export default function Aboutus() {
  return (
    <>
      {" "}
      <div id='about-us' className='padding-large'>
        <div className='container-fluid padding-side' data-aos='fade-up'>
          <h3 className='display-3 text-center fw-normal col-lg-4 offset-lg-4'>
            Innovating Workspaces, Empowering Success
          </h3>
          <div className='row align-items-start mt-3 mt-lg-5'>
            <div className='col-lg-6'>
              <div className='p-5'>
                <p>
                  At InvisiSpace, we specialize in transforming workspaces to
                  foster productivity, creativity, and collaboration. Our
                  mission is to provide businesses with flexible, fully-equipped
                  environments tailored to their unique needs, helping them
                  thrive in a dynamic world. Whether you’re a startup, a growing
                  team, or an enterprise, InvisiSpace is your partner in
                  building a workspace that supports your success.
                </p>
                {window.location.pathname.toLowerCase() === "/about" ? null : (
                  <NavLink
                    to='/about'
                    className='btn btn-arrow btn-primary mt-3'
                  >
                    <span>
                      Read About Us{" "}
                      <svg width='18' height='18'>
                        <use xlinkHref='#arrow-right'></use>
                      </svg>
                    </span>
                  </NavLink>
                )}
              </div>
              <img
                src='images/about-img1.jpg'
                alt='img'
                className='img-fluid rounded-4 mt-4'
              />
            </div>
            <div className='col-lg-6 mt-5 mt-lg-0'>
              <img
                src='images/about-img2.jpg'
                alt='img'
                className='img-fluid rounded-4'
              />
              <img
                src='images/about-img3.jpg'
                alt='img'
                className='img-fluid rounded-4 mt-4'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
